/* eslint-disable no-undef */
import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';

export const homeCta = () => {
	const homeCtaHeight = document.querySelector('.home_cta_item_wrap').offsetHeight;
	const homeCtaImg = document.querySelectorAll('.home_cta_img');
	const homeCtaText = document.querySelectorAll('.home_cta_text');
	homeCtaImg.forEach(ctaImg => { ctaImg.style.minHeight = homeCtaHeight + "px"; });
	homeCtaText.forEach(ctaTxt => { ctaTxt.style.minHeight = homeCtaHeight + "px"; });
};

export const homeTabs = () => {

	const tabs = document.querySelectorAll('.about_tab');
	const pages = document.querySelectorAll('.about_tab_content');
	const carousels = document.querySelectorAll('.about_caro');
	
	setTimeout(function(){
		tabs[0].classList.add('active');
		pages[0].classList.add('active');
		carousels[0].classList.add('active');
	}, 100);
	if(document.querySelector('.home_about_mobile_toggle')){
		document.querySelector('.home_about_mobile_toggle').addEventListener('click', function(){
			document.querySelector('.home_about_mobile_wrap').classList.add('mobile_tab_open');
		});
		document.querySelector('.home_about_fac_wrap').addEventListener('click', function(e){
			if(e.target && e.target.nodeName === 'LI'){
				document.querySelector('.home_about_mobile_wrap').classList.remove('mobile_tab_open');
				console.log("hehehehehe");
			}
		});
	}
	document.getElementById('home_about_links').addEventListener('click', function(e){
		if(e.target && e.target.nodeName === 'LI'){
			const targetPage = e.target.dataset.tab;

			[].forEach.call(tabs, function(el){
				if(el.classList.contains('active')){
					el.classList.remove('active');
				}
			});
			e.target.classList.add('active');

			[].forEach.call(pages, function(el){
				if(el.classList.contains('active')){
					el.classList.remove('active');
				}
			});
			[].forEach.call(carousels, function(el){
				if(el.classList.contains('active')){
					el.classList.remove('active');
				}
			});

			document.querySelector('.about_content_'+targetPage).classList.add('active');
			document.querySelector('.about_caro_'+targetPage).classList.add('active');
		}
	});
};

export const homeCaros = () => {
	const caros = document.querySelectorAll('.about_caro_fac_imgs');
	[].forEach.call(caros, function(e){
		const homeCaro = e.getAttribute('data-slidename');
		AutomatitCarousel({
			element: document.querySelector('#fac_caro_'+homeCaro),
			showDots: true,
			intervalTiming: 8000
		});
	});
};

export const multiMap = () => {
	/*eslint-disable*/
	let generateMap = false;

	let callback = (entries, observer) => {
		entries.forEach((entry) => {
			if(entry.isIntersecting && !generateMap) {
				_startMultiMap();
				generateMap = true;
			}
		});
	}

	let multiMapOptions = {
		root: null,
		threshold: 0.1
	};

	let observer = new IntersectionObserver(callback, multiMapOptions);
	let target = document.getElementById('multi_map');
	observer.observe(target);

	function _startMultiMap() {

		const mapHelper = getMapsHelper();
		mapHelper.ready()
			.then(() => {
				const theMap = mapHelper.createMap({
					element: document.querySelector('#multi_map'),
					locationElementSelector: '.data_source',
					useRichmarker: true,
					markerReducer: el => {
						return {
							lat: el.getAttribute('lat'),
							lng: el.getAttribute('lng'),
							content: `
								<a class="map_pin" href="${el.getAttribute('link')}" ${el.hasAttribute('blank')?'target="_blank"':''}>
									<span>${el.getAttribute('num')}</span>
									<img src="/static/images/icons/map_pin.svg" alt="Visit facility" />
								</a>
							`
						};
					},
				});

				google.maps.event.addListenerOnce(theMap, 'idle', () => {
					if(document.querySelectorAll('.data_source').length < 2) {				
						theMap.setZoom(15);
					}

					document.querySelectorAll('.data_source').forEach(i => {
						const markerData = mapHelper.getMarker(i);
						markerData.element.addEventListener('mouseenter', () => {
							markerData.element.classList.add('hover');
							i.classList.add('hover');
						});
						markerData.element.addEventListener('mouseleave', () => {
							markerData.element.classList.remove('hover');
							i.classList.remove('hover');
						});
					});
				});
			});
	}
};