import './polyfills';

import { initMobileNav, initPhoneOverlay, innitAddDoubleClick } from './header';
import { initFacilityMobile, initFacilityCaro, initFacilityTabs, initFacilityFilters, initFacilityMapToggle } from './facility';
import { initReviewsCaro } from './reviewsCaro';
import { contactSubmit } from './contact.js';
import { homeCta, homeTabs, homeCaros, multiMap } from './home';
import { initTipsSelector } from './help';

initMobileNav();
initPhoneOverlay();
innitAddDoubleClick();

if(document.querySelector('#facility_info')) {
	initFacilityMobile();
}

if(document.querySelector('#facility_caro')) {
	initFacilityCaro();
}

if(document.querySelector('#facility_content_tabs')) {
	initFacilityTabs();
}

if(document.querySelector('#facility_filters')) {
	initFacilityFilters();
}

if(document.getElementById('toggle_fac_map')) {
	initFacilityMapToggle();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#tips_selectors') && window.innerWidth > 900) {
	initTipsSelector();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'facility', 'email', 'message']
	});
}

if (document.getElementById('home_cta_wrap')) {
	homeCta();
}
if (document.getElementById('home_about_links')) {
	homeTabs();
	homeCaros();
}
if (document.getElementById('multi_map')) {
	multiMap();
}
